<script>
import { copy } from '../../lib/objects';
import Vue from 'vue';
import draggable from 'vuedraggable';

export default {
  name: 'FallbackForm',
  async beforeRouteEnter(to, from, next) {
    const [{ fallback, publishersIds }, advertiserOptions, publisherOptions] = await Promise.all([
      Vue.ovData.offer.getFallback(),
      Vue.ovData.advertiser.getOptions(true),
      Vue.ovData.publisher.getOptions(true),
    ]);
    const { records: connectedOffers } = await Vue.ovReq.get(`offer/getConnectedOffers/${fallback.id}`);

    next((vm) => {
      vm.offer = fallback;
      vm.connectedOffers = connectedOffers;
      vm.advertiserOptions = advertiserOptions;
      vm.publisherOptions = publisherOptions;
      vm.connectedPublishers = publisherOptions.filter((o) => publishersIds.includes(o.v));
      return vm;
    });
  },
  components: {
    draggable,
  },
  computed: {},
  data() {
    return {
      busy: false,
      connectedOffers: [],
      offer: {},
      advertiserOptions: [],
      publisherOptions: [],
      connectedPublishers: [],
    };
  },
  methods: {
    /**
     * Search for offers
     * @param {string} query - Offer search query
     * @param {string} id - Offer local multiselect id
     */
    async searchOffer(query, id) {
      const idx = +id.split('-')[2];
      this.connectedOffers[idx]._offerOptionsLoading = true;
      try {
        const params = {
          keyword: query,
          offer_type: 'normal',
        };
        if (this.connectedOffers[idx].advertiser) {
          params.advertiser_id = this.connectedOffers[idx].advertiser.v;
        }
        const options = await Vue.ovData.offer.getOptions(params);
        this.connectedOffers[idx]._offerOptions = options;
      } catch (e) {
        console.error(e);
      }
      this.connectedOffers[idx]._offerOptionsLoading = false;
    },

    getAdvertiserLabel(advertiser) {
      if (!advertiser) {
        return '';
      }
      /** We have label from options and from saved offer */
      return advertiser.t || `[${advertiser.id}] ${advertiser.name}`;
    },

    addConnectedOffer() {
      this.connectedOffers.push({
        _edit: true,
        _custom_weight: false,
        _offerOptions: [],
        _offerOptionsLoading: false,
        weight: 0,
        distribution_level: 3,
        offer: null,
        advertiser: null,
      });
      this.calcOffersWeight();
    },

    resetOffersWeight() {
      this.connectedOffers.forEach((o) => {
        Vue.set(o, 'distribution_level', 3);
      });
      this.calcOffersWeight();
    },

    calcOffersWeight() {
      let percentage = 100;
      let priorityOffersTotalWeight = 0;
      this.connectedOffers.forEach((o) => {
        o.distribution_level = +o.distribution_level;
        if (o.distribution_level === -1) {
          percentage -= +o.weight;
        } else {
          priorityOffersTotalWeight += o.distribution_level;
        }
      });
      if (percentage < 0) {
        percentage = 0;
      }
      this.connectedOffers
        .filter((o) => o.distribution_level >= 0)
        .forEach((o) => {
          const weight = priorityOffersTotalWeight > 0
            ? Math.round((percentage * o.distribution_level) / priorityOffersTotalWeight)
            : 0;
          Vue.set(o, 'weight', weight);
        });
    },

    /** @param {{ moved: {
     *   element: Record<string, any>,
     *   newIndex: number,
     *   oldIndex: number }
     * }} event */
    onSort(event) {
      const { oldIndex, newIndex } = event.moved;
      const arrOldIndex = oldIndex - 1;
      const arrNewIndex = newIndex - 1;
      const newConnectedOffers = copy(this.connectedOffers);
      newConnectedOffers.splice(arrNewIndex, 0, newConnectedOffers.splice(arrOldIndex, 1)[0]);
      for (let index = 0; index < newConnectedOffers.length; index++) {
        newConnectedOffers[index].display_index = index;
      }
      this.connectedOffers = newConnectedOffers;
    },

    async getConnectedOffers() {
      const resp = await this.$ovReq.get('offer/getConnectedOffers/' + this.offer.id);
      this.connectedOffers = resp.records;
    },

    async loadData() {
      const [{ fallback }, { records: connectedOffers }] = await Promise.all([
        this.$ovData.offer.getFallback(),
        this.$ovReq.get(`offer/getConnectedOffers/${this.offer.id}`),
      ]);
      this.offer = fallback;
      this.connectedOffers = connectedOffers;
    },

    async submit() {
      if (this.busy) {
        return;
      }
      this.busy = true;

      const connectedOffers = this.connectedOffers.map((o) => ({
        offerId: o.offer.id,
        weight: o.weight,
        distributionLevel: o.distribution_level,
      }));

      const data = {
        connectedOffers,
        connectedPublishers: this.connectedPublishers.map((o) => o.v),
        fallbackOfferId: this.offer.id,
        connectedOffersDistributionMethod: this.offer.complex_distribution_method,
      };

      try {
        await this.$ovData.offer.saveFallback(data);
        this.$ovNotify.success('Smartlink has been saved');
        await this.loadData();
      } catch (e) {
        console.error(e);
      } finally {
        this.busy = false;
      }
    },

    async updateStatus() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      try {
        const data = { status: this.offer.status === 'live' ? 'paused' : 'live' };
        await this.$ovData.offer.updateFallbackStatus(data);
        await this.loadData();
        this.$ovNotify.success(
          `Smartlink has been ${this.offer.status === 'live' ? 'disabled' : 'activated'}`
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.busy = false;
      }
    },
  },
};
</script>



<template lang="pug">
div

  loading(:active="busy", :is-full-page="true")

  form.form(@submit.prevent="submit")

    .d-flex.flex-wrap.align-items-center.justify-content-end.mb-2.gap-2
      label.m-0 Active
      b-checkbox(
        switch,
        :checked="offer.status === 'live'",
        @change="updateStatus",
      )
      button.btn.btn-primary.ml-2(type="submit") Save
        i.la.la-fw.la-save

    .widget
      .widget-header
        h1.title Connected Publishers
      .widget-body
        multiselect(:multiple="true", :options="publisherOptions", v-model="connectedPublishers",
          track-by="v", label="t", deselect-label="", select-label="")

    .widget
      .widget-header
        h1.title Offers
      .widget-body
        .form-group
          label Distribution method
          select.form-control(v-model="offer.complex_distribution_method")
            option(value="weights") Weights
            option(value="first_active") First Active

        table.table.table-bordered.inner-offer-list
          thead
            tr
              td(v-if="offer.complex_distribution_method !== 'weights'") &nbsp;
              th Advertiser
              th Offer
              th(v-if="offer.complex_distribution_method === 'weights'")
                | Weight
                button.btn.btn-outline-dark.ml-1(type="button", @click="resetOffersWeight()", v-b-tooltip.hover.right, title="Reset all weights")
                  i.la.la-times
              td &nbsp;

          draggable(
            tag="tbody",
            :value="connectedOffers",
            @change="onSort",
            handle=".drag-handle",
          )
            tr(v-if="connectedOffers.length === 0")
              td(colspan="7") No connected offers
            tr(
              v-if="connectedOffers.length > 0",
              v-for="(o, index) in connectedOffers",
              v-bind:key="index",
              :class=`{
                'not-live': o.offer && (o.offer.status !== 'live' || o.weight <= 0 || !o.advertiser.status),
                'not-active': o.offer && o.offer.status === 'live' && o.offer.effective_status !== 'active'
              }`
            )
              td(v-if="offer.complex_distribution_method !== 'weights'")
                div.drag-handle
                  svg(width="24px", fill="currentColor", viewBox="0 0 24 24")
                    path(d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z")
                    path(d="M0 0h24v24H0z", fill="none")

              td
                multiselect(v-if="!o.offer", :multiple="false", :options="advertiserOptions", v-model="o.advertiser",
                  track-by="v", label="t", deselect-label="", select-label="")
                .input-group(v-if="o.offer")
                  input.form-control(type="text", placeholder="", readonly="readonly", :value="getAdvertiserLabel(o.advertiser)")
                  .input-group-append(v-if="o.advertiser && !o.advertiser.status")
                    .form-control(v-b-tooltip.hover.bottom, title="This Advertiser has been Paused")
                      i.la.la-warning

              td
                multiselect(v-if="o._edit", :multiple="false", :options="o._offerOptions", v-model="o.offer",
                  :limit="1", group-label="name", group-values="items", :group-select="false",
                  track-by="id", label="name", deselect-label="", select-label="",
                  :id="'connected-offer-'+index", @search-change="searchOffer",
                  :loading="o._offerOptionsLoading", :internal-search="false")
                .input-group(v-if="!o._edit")
                  input.form-control(type="text", placeholder="", readonly="readonly", v-model="'['+o.offer.id+'] '+o.offer.name")
                  span.s2s-badge(v-if="o.offer.s2s") S2S
                  .input-group-append
                    router-link.btn.btn-secondary(
                      :to="{ name:o.offer.type==='complex' ? 'complex-edit' : 'offer-edit', params: { id:o.offer.id } }",
                      target='_blank',
                      v-b-tooltip.hover.right,
                      title="View offer"
                    )
                      i.la.la-external-link

              td(v-if="offer.complex_distribution_method == 'weights'")
                input.form-control.input-numeric(
                  v-if="o._edit && o.distribution_level === -1",
                  v-model="o.weight",
                  type="number",
                  @change="calcOffersWeight"
                )
                priority(v-if="o.distribution_level >= 0", v-model="o.distribution_level", v-on:input="calcOffersWeight")
                i.la.la-thumbtack(
                  v-if="o.distribution_level === -1 && !o._edit",
                  style="font-size: 22px; display: block",
                  v-b-tooltip.hover.right,
                  title="Fixed weight"
                )
                //- b-form-checkbox(v-if="o._edit", v-model="o._custom_weight",  @change="onCustomWeightChange(o)") Fixed
                span(:class="{'text-muted': o.distribution_level >= 0}") {{ o.weight }}%

              td.actions
                button.btn.btn-danger(type="button", @click="connectedOffers.splice(index, 1); calcOffersWeight()")
                  i.la.la-trash

        button.btn.btn-secondary(type="button", @click="addConnectedOffer()") Add
</template>



<style lang="scss">
.inner-offer-list {
  tr.not-live {
    background: lighten(#dc3545, 40%);

    .form-control,
    .progress {
      background: lighten(#dc3545, 36%);
    }
  }

  tr.not-active {
    background: lighten(#dc7d35, 40%);

    .form-control,
    .progress {
      background: lighten(#dc7d35, 36%);
    }
  }
}

.drag-handle {
  cursor: grab !important;
}

.main-switcher-container {
  display: flex;
  align-items: center;
  background-color: #e9ecef;
  padding: 0 0.25rem;

  .la-link {
    color: var(--primary);
  }

  .la-unlink {
    color: #ccc;
  }

  .main-switcher {
    font-size: 20px;
  }
}

.inner-offer-list tr.not-active .main-switcher-container {
  background: #f7e2d1;
}

.s2s-badge {
  background: #e9ecef;
  font-size: 10px;
  padding: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
